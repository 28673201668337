import React from 'react'

const App = () => {
  return (
    <>
<Header/>
    </>
  )
}

export default App

const Header=() =>{
    return(
        <nav className='flex justify-center items-center bg-blue-400 h-24' >
            <ul className='flex flex-row space-x-4 uppercase text-white' >
                <li >home</li>
                <li >About</li>
                <li >Service</li>
                <li >Contact</li>
                <li >Email Address</li>
            </ul>
        </nav>
    )
}